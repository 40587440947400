<template lang="">
  <div>
      <b-card>            
          <div class="row pr-1">
              <div class="col-12">
                  <h4 class="card-title mb-3">
                      {{ $t(`ROTULO_BOLETOS.TITULO_FORMULARIO_${!id ? "NOVO" : "EDICAO"}`) }}
                  </h4>
              </div>

              <input-checkbox 
                id="input-eh-boleto" 
                class="col-12 mb-3"
                switch 
                :text="$t('ROTULO_BOLETOS.EH_BOLETO')" 
                v-model="form.ehBoleto" 
                ref="form-eh-boleto" 
              />
             

              <input-text
                id="input-codigo"
                class="col-12 col-sm-6"
                :label="$t('ROTULO_BOLETOS.CODIGO_ROTULO')" 
                v-model="form.codigo"
                ref="codigo" 
                type="number" 
                required
              />

              <input-text 
                id="input-nome"
                class="col-12 col-sm-6" 
                :label="$t('ROTULO_BOLETOS.NOME_ROTULO')" 
                v-model="form.nome" 
                ref="nome" 
                required
              />

              <div class="col-12 mt-4 d-flex justify-content-end">
                  <b-button id="btn-cancelar" @click="cancelar" variant="outline-success">
                    {{ $t(`GERAL.CANCELAR`) }}
                  </b-button>        
                  <b-button id="btn-salvar" @click="salvar" variant="success" class="ml-2" v-if="form.id ? permissao.editar : permissao.criar">
                    {{ $t(`GERAL.SALVAR`) }}
                  </b-button>        
              </div>
          </div>
      </b-card>
  </div>
</template>

<script>
import RotuloBoletosService from "@/common/services/rotulo-boletos/rotulo-boletos.service";
import { InputText, InputCheckbox } from "@/components/inputs";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "RotuloBoletosForm",
  components: {
    InputText,
    InputCheckbox
  },
  props: {
    id: {},
  },
  data: function () {
    return {
      form: {
        nome: "",
        codigo: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      {
        titulo: this.$t("ROTULO_BOLETOS.TITULO_LISTA"),
        to: "/rotulo_boletos",
      },
      {
        titulo: this.$t(`ROTULO_BOLETOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`),
      },
    ]);
    
    console.log(this.id);
    if (this.id) {
      this.buscar();
    }
  },
  methods: {
    salvar: function () {
      return new Promise(() => {
        this.confirmar(
          this.$t(`ROTULO_BOLETOS.TITULO_SALVAR`),
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then((confirmado) => {
          if (!confirmado) 
            return;

          if (!this.validarFormulario()) 
            return false;

          if (this.id){
            this.editar();
          }
          else {
            this.criar();
          }
        });
      });
    },
    criar: function () {
      this.$store.dispatch(START_LOADING);
      let form = cloneDeep(this.form);

      RotuloBoletosService.criar(form)
        .then(() => {
          this.alertaSucesso(
            this.$t("ROTULO_BOLETOS.MENSAGEM_SUCESSO")
          ).then(() => {
            this.$router.push({ name: "rotulo-boletos" });
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar: function () {
      this.$store.dispatch(START_LOADING);
      let form = cloneDeep(this.form);
      
      RotuloBoletosService.editar(form)
        .then(() => {
          this.alertaSucesso(
            this.$t("ROTULO_BOLETOS.MENSAGEM_SUCESSO")
          ).then(() => {
            this.$router.push({ name: "rotulo-boletos" });
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);

      RotuloBoletosService.buscarPorId(this.id)
        .then((result) => {
          let data = result.data.data;
          this.form = data;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    cancelar: function () {
      this.$router.push({ name: "rotulo-boletos" });
    },
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("RotuloBoletos");
    },
  }
};
</script>